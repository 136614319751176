<template>
  <section class="section dashboard">

    <!-- filter -->
    <div class="row filter">
      <div class="col-md-12">

        <div class="card">
          <div class="card-body">

            <!-- form filter -->
            <form>

              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label>Empresa (Centro de Custos)</label>
                    <v-select :options="companiesOptions" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="filter.company_id"></v-select>
                  </div>
                </div>

                <div class="col-md-3">
                  <div class="form-group">
                    <label>Mês</label>
                    <v-select :options="monthOptions" label="label" :reduce="label => label.id" v-model="filter.month"></v-select>
                  </div>
                </div>

                <div class="col-md-2">
                  <div class="form-group">
                    <label>Ano</label>
                    <input type="text" class="form-control" v-mask="'####'" name="year" v-model="filter.year" />
                  </div>
                </div>

                <div class="col-md-1">
                  <button class="btn btn-outline-warning" type="submit" title="filtrar">
                    <i class="fas fa-funnel-dollar"></i>
                  </button>
                </div>
              </div>

            </form>

          </div>
        </div>

      </div>
    </div>

    <!-- widgets -->
    <!-- summary -->
    <div class="row">
      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-info">
            <i class="fas fa-university"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Saldo Atual</h4>
            </div>
            <div class="card-body">
              R$ {{ formatPrice(revenue.done - expense.done) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-6 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-secondary">
            <i class="fas fa-credit-card"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Cartões</h4>
            </div>
            <div class="card-body">
              R$ 0.00
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- receitas -->
    <div class="row">
      <div class="col-md-12">
        <p>Receitas</p>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-success">
            <i class="fas fa-arrow-up"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Receitas (Total)</h4>
            </div>
            <div class="card-body">
              R$ {{ formatPrice(revenue.total) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-success">
            <i class="fas fa-arrow-up"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Receitas (Recebidos)</h4>
            </div>
            <div class="card-body">
              R$ {{ formatPrice(revenue.done) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-warning">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Receitas (Pendentes)</h4>
            </div>
            <div class="card-body">
              R$ {{ formatPrice(revenue.pending) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- despesas -->
    <div class="row">
      <div class="col-md-12">
        <p>Despesas</p>
      </div>

      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-danger">
            <i class="fas fa-arrow-down"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Despesas (Total)</h4>
            </div>
            <div class="card-body">
              R$ {{ formatPrice(expense.total) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-danger">
            <i class="fas fa-arrow-down"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Despesas (Recebidos)</h4>
            </div>
            <div class="card-body">
              R$ {{ formatPrice(expense.done) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-warning">
            <i class="fas fa-exclamation-triangle"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Despesas (Pendentes)</h4>
            </div>
            <div class="card-body">
              R$ {{ formatPrice(expense.pending) }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- receitas and despesas pending -->
    <div class="row">
      <div class="col-md-6">

        <div class="card">
          <div class="card-header text-primary">
            Receitas (Pendentes)
          </div>

          <div class="card-body p-4">

            <vue-good-table v-if="revenuesItems.length"
              :columns="tableColumns"
              :rows="revenuesItems"
              :pagination-options="tablePagination"
              :search-options="{
                enabled: true,
                placeholder: 'Buscar..',
              }"
              styleClass="vgt-table"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == '_value'">
                  R$ {{ formatPrice(props.row.value) }}
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
            <div v-else class="text-center p-3 text-muted">
              <i class="far fa-grin-wink icon-dash-empty"></i>
              <h5>Nenhuma receita pendente!</h5>
            </div>

          </div>
        </div>

      </div>

      <div class="col-md-6">

        <div class="card">
          <div class="card-header text-primary">
            Despesas (Pendentes)
          </div>

          <div class="card-body p-4">

            <vue-good-table v-if="expensesItems.length"
              :columns="tableColumns"
              :rows="expensesItems"
              :pagination-options="tablePagination"
              :search-options="{
                enabled: true,
                placeholder: 'Buscar..',
              }"
              styleClass="vgt-table"
            >
              <template slot="table-row" slot-scope="props">
                <span v-if="props.column.field == '_value'">
                  R$ {{ formatPrice(props.row.value) }}
                </span>
                <span v-else>
                  {{ props.formattedRow[props.column.field] }}
                </span>
              </template>
            </vue-good-table>
            <div v-else class="text-center p-3 text-muted">
              <i class="far fa-grin-wink icon-dash-empty"></i>
              <h5>Nenhuma despesa pendente!</h5>
            </div>

          </div>
        </div>

      </div>
    </div>
    
  </section>
</template>
<script>
  import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: [],
		data() {
			return {
        companiesOptions: [],
        expense: {
          done: 0,
          pending: 0,
          total: 0
        },
        expensesItems: [],
        filter: {
          company_id: null,
          month: null,
          year: null
        },
        monthOptions: [{
          id: 1,
          label: 'Janeiro'
        },{
          id: 2,
          label: 'Fevereiro'
        },{
          id: 3,
          label: 'Março'
        },{
          id: 4,
          label: 'Abril'
        },{
          id: 5,
          label: 'Maio'
        },{
          id: 6,
          label: 'Junho'
        },{
          id: 7,
          label: 'Julho'
        },{
          id: 8,
          label: 'Agosto'
        },{
          id: 9,
          label: 'Setembro'
        },{
          id: 10,
          label: 'Outubro'
        },{
          id: 11,
          label: 'Novembro'
        },{
          id: 12,
          label: 'Dezembro'
        }],
        revenue: {
          done: 0,
          pending: 0,
          total: 0
        },
        revenuesItems: [],
        tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'Valor',
						field: '_value',
					},{
						label: 'Vencimento',
						field: 'due_date',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd",
						dateOutputFormat: 'dd/MM/yyyy'
					},{
						label: 'Descrição',
						field: 'description',
					},{
						label: 'Centro de Custo',
						field: 'companies.corporate_name',
					}
				],
      }
    },
    mounted() {
      this.loadInfo();
    },
    methods: {
			loadInfo() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/dashboard/info").then((res) => {
					
          _this.companiesOptions = res.data.companies;

          _this.revenuesItems = res.data.revenues;
          _this.expensesItems = res.data.expenses;

          _this.expense.done = parseFloat(res.data.expenses_done);
          _this.expense.pending = parseFloat(res.data.expenses_pending);
          _this.expense.total = parseFloat(res.data.expenses_done) + parseFloat(res.data.expenses_pending);

          _this.revenue.done = parseFloat(res.data.revenues_done);
          _this.revenue.pending = parseFloat(res.data.revenues_pending);
          _this.revenue.total = parseFloat(res.data.revenues_done) + parseFloat(res.data.revenues_pending);

					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
      formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      }
    }};
</script>