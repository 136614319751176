<template>
	<div class="row access-groups">
		<div class="col">

			<!-- top -->
			<div class="row section-top">
				<div class="col-md-12 pad0">
					<p class="section-lead">Preencha os campos abaixo</p>
					<a href="#" @click="saveData()" class="btn btn-primary float-right">SALVAR</a>
					<a href="#" @click="type = 'menu'" class="btn btn-secondary float-right" style="margin-right: 15px;" v-if="type != 'menu'">VER MENU</a>
				</div>
			</div>
			<!-- end top -->

			<!-- name -->
			<div class="card">
				<div class="card-body">
					<div class="form-group">
						<label>Nome do Grupo de Acesso</label>
						<input class="form-control" v-model="name" id="name"/>
					</div>
				</div>
			</div>
			<!-- end name -->

			<!-- menu -->
			<div class="row menu" v-if="type == 'menu'">
				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-cog"></i>
						</div>
						<div class="card-body">
							<h4>Painel Administrativo</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'admin'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>
				<div class="col-lg-6">
					<div class="card card-large-icons">
						<div class="card-icon bg-primary text-white">
							<i class="fas fa-cog"></i>
						</div>
						<div class="card-body">
							<h4>Sistema</h4>
							<p>Permissões relacionadas às funcionalidades do Painel Administrativo</p>
							<a href="#" @click="type = 'system'" class="card-cta">Ver Permissões <i class="fas fa-chevron-right"></i></a>
						</div>
					</div>
				</div>
			</div>
			<!-- end menu -->

			<!-- itens admin -->
			<div class="row list" v-if="type == 'admin'">

				<!-- list all admin permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.admin" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all admin permissions -->

			</div>
			<!-- end itens admin -->

			<!-- itens system -->
			<div class="row list" v-if="type == 'system'">

				<!-- list all system permissions -->
				<div class="col-md-4" v-for="_permission, index in permission.system" :key="index">
					<div class="card">
						<div class="card-header">
							{{_permission.title}}
						</div>
						<div class="card-body">
							<div class="item" v-for="_item, itemIndex in _permission.items" :key="itemIndex">
								{{_item.title}}
								<label class="custom-switch">
									<input type="checkbox" v-model="_item.value" v-on:change="changeToggle(_item.name)" class="custom-switch-input">
									<span class="custom-switch-indicator"></span>
								</label>
							</div>
						</div>
					</div>
				</div>
				<!-- end list all system permissions -->

			</div>
			<!-- end itens system -->

		</div>

		<vue-loading color="#ec2423" :active.sync="loading" :can-cancel="false"></vue-loading>
	</div><!-- row -->
</template>

<script>
	import ApiService from "../../apiservice.js";
	export default {
		mixins: [ApiService],
		props: ['info','role'],
		data() {
			return {
				loading: false,
				name: this.getDataRole("name"),
				permission: {
					admin: [
						{
							title: "Grupos de Acesso",
							items: [
								{
									title: "Listar",
									name: "admin-permissions-list",
									value: this.getData("admin-permissions-list")
								},{
									title: "Criar",
									name: "admin-permissions-create",
									value: this.getData("admin-permissions-create")
								},{
									title: "Editar",
									name: "admin-permissions-edit",
									value: this.getData("admin-permissions-edit")
								},{
									title: "Deletar",
									name: "admin-permissions-delete",
									value: this.getData("admin-permissions-delete")
								}
							]
						},{
							title: "Usuários",
							items: [
								{
									title: "Listar",
									name: "admin-users-list",
									value: this.getData("admin-users-list")
								},{
									title: "Criar",
									name: "admin-users-create",
									value: this.getData("admin-users-create")
								},{
									title: "Editar",
									name: "admin-users-edit",
									value: this.getData("admin-users-edit")
								},{
									title: "Deletar",
									name: "admin-users-delete",
									value: this.getData("admin-users-delete")
								},{
									title: "Desativar",
									name: "admin-users-disable",
									value: this.getData("admin-users-disable")
								},{
									title: "Resetar Senha",
									name: "admin-users-resetpassword",
									value: this.getData("admin-users-resetpassword")
								}
							]
						},{
							title: "Logs",
							items: [
								{
									title: "Log Acesso",
									name: "admin-logs-access",
									value: this.getData("admin-logs-access")
								},{
									title: "Log API",
									name: "admin-logs-api",
									value: this.getData("admin-logs-api")
								},{
									title: "Log Atividade",
									name: "admin-logs-activity",
									value: this.getData("admin-logs-activity")
								},{
									title: "Log Erro",
									name: "admin-logs-error",
									value: this.getData("admin-logs-error")
								},{
									title: "Log de Falhas",
									name: "admin-logs-failedjobs",
									value: this.getData("admin-logs-failedjobs")
								}
							]
						}
					],
					system: [
						{
							title: "Movimentações",
							items: [
								{
									title: "Listar",
									name: "admin-movements-list",
									value: this.getData("admin-movements-list")
								},{
									title: "Criar",
									name: "admin-movements-create",
									value: this.getData("admin-movements-create")
								},{
									title: "Editar",
									name: "admin-movements-edit",
									value: this.getData("admin-movements-edit")
								},{
									title: "Deletar",
									name: "admin-movements-delete",
									value: this.getData("admin-movements-delete")
								}
							]
						},
						{
							title: "Fornecedores",
							items: [
								{
									title: "Listar",
									name: "admin-suppliers-list",
									value: this.getData("admin-suppliers-list")
								},{
									title: "Criar",
									name: "admin-suppliers-create",
									value: this.getData("admin-suppliers-create")
								},{
									title: "Editar",
									name: "admin-suppliers-edit",
									value: this.getData("admin-suppliers-edit")
								},{
									title: "Deletar",
									name: "admin-suppliers-delete",
									value: this.getData("admin-suppliers-delete")
								}
							]
						},
						{
							title: "Clientes",
							items: [
								{
									title: "Listar",
									name: "admin-clients-list",
									value: this.getData("admin-clients-list")
								},{
									title: "Criar",
									name: "admin-clients-create",
									value: this.getData("admin-clients-create")
								},{
									title: "Editar",
									name: "admin-clients-edit",
									value: this.getData("admin-clients-edit")
								},{
									title: "Deletar",
									name: "admin-clients-delete",
									value: this.getData("admin-clients-delete")
								}
							]
						},
						{
							title: "Produtos",
							items: [
								{
									title: "Listar",
									name: "admin-products-list",
									value: this.getData("admin-products-list")
								},{
									title: "Criar",
									name: "admin-products-create",
									value: this.getData("admin-products-create")
								},{
									title: "Editar",
									name: "admin-products-edit",
									value: this.getData("admin-products-edit")
								},{
									title: "Deletar",
									name: "admin-products-delete",
									value: this.getData("admin-products-delete")
								}
							]
						},
						{
							title: "Cartões",
							items: [
								{
									title: "Listar",
									name: "admin-cards-list",
									value: this.getData("admin-cards-list")
								},{
									title: "Criar",
									name: "admin-cards-create",
									value: this.getData("admin-cards-create")
								},{
									title: "Editar",
									name: "admin-cards-edit",
									value: this.getData("admin-cards-edit")
								},{
									title: "Deletar",
									name: "admin-cards-delete",
									value: this.getData("admin-cards-delete")
								}
							]
						},
						{
							title: "Bancos",
							items: [
								{
									title: "Listar",
									name: "admin-banks-list",
									value: this.getData("admin-banks-list")
								},{
									title: "Criar",
									name: "admin-banks-create",
									value: this.getData("admin-banks-create")
								},{
									title: "Editar",
									name: "admin-banks-edit",
									value: this.getData("admin-banks-edit")
								},{
									title: "Deletar",
									name: "admin-banks-delete",
									value: this.getData("admin-banks-delete")
								}
							]
						},
						{
							title: "Contas",
							items: [
								{
									title: "Listar",
									name: "admin-accounts-list",
									value: this.getData("admin-accounts-list")
								},{
									title: "Criar",
									name: "admin-accounts-create",
									value: this.getData("admin-accounts-create")
								},{
									title: "Editar",
									name: "admin-accounts-edit",
									value: this.getData("admin-accounts-edit")
								},{
									title: "Deletar",
									name: "admin-accounts-delete",
									value: this.getData("admin-accounts-delete")
								}
							]
						},
						{
							title: "Empresas",
							items: [
								{
									title: "Listar",
									name: "admin-companies-list",
									value: this.getData("admin-companies-list")
								},{
									title: "Criar",
									name: "admin-companies-create",
									value: this.getData("admin-companies-create")
								},{
									title: "Editar",
									name: "admin-companies-edit",
									value: this.getData("admin-companies-edit")
								},{
									title: "Deletar",
									name: "admin-companies-delete",
									value: this.getData("admin-companies-delete")
								}
							]
						},
						{
							title: "Categorias",
							items: [
								{
									title: "Listar",
									name: "admin-categories-list",
									value: this.getData("admin-categories-list")
								},{
									title: "Criar",
									name: "admin-categories-create",
									value: this.getData("admin-categories-create")
								},{
									title: "Editar",
									name: "admin-categories-edit",
									value: this.getData("admin-categories-edit")
								},{
									title: "Deletar",
									name: "admin-categories-delete",
									value: this.getData("admin-categories-delete")
								}
							]
						},
						{
							title: "Subcategorias",
							items: [
								{
									title: "Listar",
									name: "admin-subcategories-list",
									value: this.getData("admin-subcategories-list")
								},{
									title: "Criar",
									name: "admin-subcategories-create",
									value: this.getData("admin-subcategories-create")
								},{
									title: "Editar",
									name: "admin-subcategories-edit",
									value: this.getData("admin-subcategories-edit")
								},{
									title: "Deletar",
									name: "admin-subcategories-delete",
									value: this.getData("admin-subcategories-delete")
								}
							]
						}
					]
				},
				permissions: [],
				permissionsRemoved: [],
				type: "menu",
			}
		},
		mounted() {
		},
		methods: {
			getData(key) {

				var temp = JSON.parse(this.info);
				var check = temp.includes(key);

				//validate check
				if (check){

					return true;

				} else {

					return false;

				}
				//end if validate check

			},
			getDataRole(key){
				return JSON.parse(this.role)[key];
			},
			changeToggle(toggle) {

				const index = this.permissions.indexOf(toggle)

				if (index == -1) {

					this.permissions.push(toggle)

					const indexRemoved = this.permissionsRemoved.indexOf(toggle)

					if (indexRemoved != -1) {
						this.permissionsRemoved.splice(indexRemoved, 1)
					}

				} else {

					this.permissions.splice(index, 1)

					const indexRemoved = this.permissionsRemoved.indexOf(toggle)

					if (indexRemoved == -1) {
						this.permissionsRemoved.push(toggle)
					}
				}

			},
			saveData() {
				if (this.name != ""){

					let _this = this;
					_this.errors = [];
					_this.message = "";
					_this.loading = true;

					let dataPermission = {
						"name": _this.name,
						"permission": _this.permission,
						"permissions": _this.permissions,
						"removed": _this.permissionsRemoved,
						"_method": "PATCH"
					};
					axios.post("/admin/permissions/"+_this.getDataRole('id'), dataPermission).then((res) => {

						_this.loading = false;

						//call function in ApiService to validate success";
						_this._api_validateSuccess(res);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			}
		}
	}
</script>