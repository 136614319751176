require('./bootstrap');

window.Vue = require('vue');

//ALERT
import VueSweetalert2 from 'vue-sweetalert2';
Vue.use(VueSweetalert2);

//CKeditor
import CKEditor from '@ckeditor/ckeditor5-vue';
Vue.use( CKEditor );

//LOADING
import Loading from 'vue-loading-overlay';
import 'vue-loading-overlay/dist/vue-loading.css';
Vue.use(Loading);
Vue.component('vue-loading', Loading);

//MASK
import VueTheMask from 'vue-the-mask';
Vue.use(VueTheMask);

//MOMENT
Vue.use(require('vue-moment'));

//VUE-GOOD-TABLE
import VueGoodTablePlugin from 'vue-good-table';
import 'vue-good-table/dist/vue-good-table.css'
Vue.use(VueGoodTablePlugin);

//VUE SELECT
import vSelect from 'vue-select'
Vue.component('v-select', vSelect);
import 'vue-select/dist/vue-select.css';

//V-money
import money from 'v-money';
Vue.use(money, {precision: 4});


//COMPONENTS
//dashboard
import adminWidgetsComponent from './components/admin/dashboard/widgets';
Vue.component('admin-widgets-component', adminWidgetsComponent);

//logs
import LogAccessComponent from './components/admin/logs/access';
import LogActivityComponent from './components/admin/logs/activity';
import LogErrorComponent from './components/admin/logs/error';
Vue.component('logaccess-component',LogAccessComponent);
Vue.component('logactivity-component',LogActivityComponent);
Vue.component('logerror-component',LogErrorComponent);

//permissions
import permissionAddComponent from './components/admin/permissions/add';
import permissionEditComponent from './components/admin/permissions/edit';
import permissionListComponent from './components/admin/permissions/list';
Vue.component('permissions-add-component', permissionAddComponent);
Vue.component('permissions-edit-component', permissionEditComponent);
Vue.component('permissions-list-component', permissionListComponent);

//users
import userListComponent from './components/admin/users/list';
import userAddComponent from './components/admin/users/add';
import userEditComponent from './components/admin/users/edit';
import userProfileComponent from './components/admin/users/profile'
Vue.component('userlist-component', userListComponent);
Vue.component('useradd-component', userAddComponent);
Vue.component('useredit-component', userEditComponent);
Vue.component('userprofile-component', userProfileComponent);

//movements
import movementsListComponent from './components/admin/movements/list';
Vue.component('movements-list-component', movementsListComponent);

//suppliers
import suppliersListComponent from './components/admin/suppliers/list';
Vue.component('suppliers-list-component', suppliersListComponent);
import suppliersAddComponent from './components/admin/suppliers/add';
Vue.component('suppliers-add-component', suppliersAddComponent);
import suppliersEditComponent from './components/admin/suppliers/edit';
Vue.component('suppliers-edit-component', suppliersEditComponent);

//clients
import clientsListComponent from './components/admin/clients/list';
Vue.component('clients-list-component', clientsListComponent);
import clientsAddComponent from './components/admin/clients/add';
Vue.component('clients-add-component', clientsAddComponent);
import clientsEditComponent from './components/admin/clients/edit';
Vue.component('clients-edit-component', clientsEditComponent);

//products
import productsListComponent from './components/admin/products/list';
Vue.component('products-list-component', productsListComponent);

//cards
import cardsListComponent from './components/admin/cards/list';
Vue.component('cards-list-component', cardsListComponent);

//banks
import banksListComponent from './components/admin/banks/list';
Vue.component('banks-list-component', banksListComponent);

//accounts
import accountsListComponent from './components/admin/accounts/list';
Vue.component('accounts-list-component', accountsListComponent);

//companies
import companiesListComponent from './components/admin/companies/list';
Vue.component('companies-list-component', companiesListComponent);
import companiesAddComponent from './components/admin/companies/add';
Vue.component('companies-add-component', companiesAddComponent);
import companiesEditComponent from './components/admin/companies/edit';
Vue.component('companies-edit-component', companiesEditComponent);

//subcategories
import subcategoriesListComponent from './components/admin/subcategories/list';
Vue.component('subcategories-list-component', subcategoriesListComponent);

//categories
import categoriesListComponent from './components/admin/categories/list';
Vue.component('categories-list-component', categoriesListComponent);


//RENDER
const app = new Vue({
	el: '#app',
	methods: {
		MakeUrl(path) {
			return BaseUrl(path);
		}
	}
});
