<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todas Categorias cadastradas</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalCategories"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="categoriesItems.length"
						:columns="tableColumns"
						:rows="categoriesItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_image'">
								<img :src="'/storage/categories/'+props.row.icon" width="32"/>
							</span>
							<span v-if="props.column.field == '_type'">
								<div v-if="props.row.type === 'default'">Todos</div>
								<div v-if="props.row.type === 'expenses'">Despesas</div>
								<div v-if="props.row.type === 'revenues'">Receitas</div>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhuma categoria cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalCategories" tabindex="-1" aria-labelledby="modalCategoriesLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalCategoriesLabel">{{categoriesTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- name -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-name">Nome</label>
											<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}"   class="form-control" placeholder="Nome" v-model="category.name" required="false" >
											<div class="invalid-feedback" v-if="errors.name">
												<p>{{ errors.name[0] }}</p>
											</div>
										</div>
									</div>

									<!-- type -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-type">Tipo</label>
											<v-select :options="typeOptions" v-model="category.type" :reduce="label => label.id"></v-select>
											<div class="invalid-feedback" v-if="errors.type">
												<p>{{ errors.type[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- icon -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-icon">Ícone (não obrigatório)</label>
											<div class="custom-file">
												<input type="file" class="custom-file-input" id="icon" name="icon" accept="image/*" ref="icon" lang="pt" v-on:change="handleFileUpload()">
												<label class="custom-file-label" for="icon">{{fileIconName}}</label>
											</div>
											<div class="invalid-feedback" v-if="errors.icon">
												<p>{{ errors.icon[0] }}</p>
											</div>
											<img :src="selectedFile" v-if="selectedFile" style="width: 50%; margin: 10px 25%;"/>
										</div>
									</div>

								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				fileIconName: "Selecione o arquivo",
				categoriesTitle: "Nova Categoria",
				category: {
					name: '',
					icon: '',
					type: 'default',
				},
				isEditCategories: false,
				categoriesID: "",
				categoriesItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				selectedFile: null,
				typeOptions: [{
					id: 'default',
					label: 'Todos'
				},{
					id: 'expenses',
					label: 'Despesas'
				},{
					id: 'revenues',
					label: 'Receitas'
				}],
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: '',
						field: '_image',
						width: '80px'
					},{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Tipo',
						field: '_type',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/categories").then((res) => {
					_this.categoriesItems = _this.categoriesItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			handleFileUpload(){
				this.category.icon = this.$refs.icon.files[0];
				this.fileIconName = this.$refs.icon.files[0].name;
				this.selectedFile = URL.createObjectURL(this.$refs.icon.files[0])
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditCategories == true){

					let formData = new FormData();
					formData.append("name", _this.category.name);
					formData.append("type", _this.category.type);
					formData.append("icon", _this.category.icon);
					formData.append("_method", "PATCH");

					axios.post("/admin/categories/"+_this.categoriesID, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalCategories').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let formData = new FormData();
					formData.append("name", _this.category.name);
					formData.append("type", _this.category.type);
					formData.append("icon", _this.category.icon);

					axios.post("/admin/categories", formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.categoriesItems.push(res.data.data);
						$('#modalCategories').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Categoria",
					text: "Tem certeza que deseja excluir essa Categoria?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/categories/"+ID).then((res) => {

							_this.categories.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.category = item;
				this.isEditCategories = true;
				this.categoriesID = item.id;
				this.categoriesTitle = "Editar Categoria";

				$("#modalCategories").modal('show');

			},
			resetForm(){

				this.category = {
					name: '',
					icon: '',
					type: 'default',
				};

				this.errors = [];
				this.isEditCategories = false;
				this.categoriesID = "";
				this.categoriesTitle = "Nova Categoria";

			}
		}
	}
</script>
