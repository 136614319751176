<template>
	<div>
		<div class="row">
			<div class="col-12">
				<div class="card">

					<!-- card header -->
					<div class="card-header">
						<h4>Preencha os dados abaixo</h4>

						<a @click="newData" class="btn btn-sm btn-success text-white btn-right">SALVAR</a>
					</div>
					<!-- end card header -->

					<!-- card body -->
					<div class="card-body">

						<!-- form -->
						<h6 class="heading-small text-muted mb-4">Dados</h6>
						<div class="">
							<div class="row">

								<!-- corporate_name -->
								<div class="col-md-8">
									<div class="form-group">
										<label class="form-control-label" for="input-corporate_name">Razão Social</label>
										<input type="text" id="input-corporate_name" v-bind:class="{'is-invalid': errors.corporate_name}"   class="form-control" placeholder="Razão Social" v-model="corporate_name" required="true" >
										<div class="invalid-feedback" v-if="errors.corporate_name">
											<p>{{ errors.corporate_name[0] }}</p>
										</div>
									</div>
								</div>

								<!-- cnpj -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-cnpj">CNPJ</label>
										<div class="input-group mb-3">
											<input type="text" id="input-cnpj" v-bind:class="{'is-invalid': errors.cnpj}" class="form-control" placeholder="CNPJ" v-model="cnpj" required="true" v-mask="'##.###.###/####-##'" aria-describedby="client-cnpj">
											<div class="input-group-append">
												<button @click="searchCNPJ()" class="btn btn-outline-primary" type="button">
													<i class="fas fa-search"></i>
												</button>
											</div>
										</div>
										<div class="invalid-feedback" v-if="errors.cnpj">
											<p>{{ errors.cnpj[0] }}</p>
										</div>
									</div>
								</div>

								<!-- zip_code -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-zip_code">CEP</label>
										<div class="input-group mb-3">
											<input type="text" id="input-zip_code" v-bind:class="{'is-invalid': errors.zip_code}"  maxlength="20" class="form-control" placeholder="CEP" v-model="zip_code" required="false" v-mask="'##.###-###'" aria-describedby="client-zip_code">
											<div class="input-group-append">
												<button @click="searchCEP()" class="btn btn-outline-primary" type="button">
													<i class="fas fa-search"></i>
												</button>
											</div>
										</div>
										<div class="invalid-feedback" v-if="errors.cep">
											<p>{{ errors.cep[0] }}</p>
										</div>
									</div>
								</div>

								<!-- address -->
								<div class="col-md-6">
									<div class="form-group">
										<label class="form-control-label" for="input-address">Endereço</label>
										<input type="text" id="input-address" v-bind:class="{'is-invalid': errors.address}"   class="form-control" placeholder="Endereço" v-model="address" required="false" >
										<div class="invalid-feedback" v-if="errors.address">
											<p>{{ errors.address[0] }}</p>
										</div>
									</div>
								</div>

								<!-- number -->
								<div class="col-md-3">
									<div class="form-group">
										<label class="form-control-label" for="input-number">Número</label>
										<input type="text" id="input-number" v-bind:class="{'is-invalid': errors.number}"  maxlength="100" class="form-control" placeholder="Número" v-model="number" required="false" >
										<div class="invalid-feedback" v-if="errors.number">
											<p>{{ errors.number[0] }}</p>
										</div>
									</div>
								</div>

								<!-- complement -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-complement">Complemento</label>
										<input type="text" id="input-complement" v-bind:class="{'is-invalid': errors.complement}"   class="form-control" placeholder="Complemento" v-model="complement" required="false" >
										<div class="invalid-feedback" v-if="errors.complement">
											<p>{{ errors.complement[0] }}</p>
										</div>
									</div>
								</div>

								<!-- neighborhood -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-neighborhood">Bairro</label>
										<input type="text" id="input-neighborhood" v-bind:class="{'is-invalid': errors.neighborhood}"   class="form-control" placeholder="Bairro" v-model="neighborhood" required="false" >
										<div class="invalid-feedback" v-if="errors.neighborhood">
											<p>{{ errors.neighborhood[0] }}</p>
										</div>
									</div>
								</div>

								<!-- city -->
								<div class="col-md-4">
									<div class="form-group">
										<label class="form-control-label" for="input-city">Cidade</label>
										<input type="text" id="input-city" v-bind:class="{'is-invalid': errors.city}"   class="form-control" placeholder="Cidade" v-model="city" required="false" >
										<div class="invalid-feedback" v-if="errors.city">
											<p>{{ errors.city[0] }}</p>
										</div>
									</div>
								</div>

								<!-- state -->
								<div class="col-md-2">
									<div class="form-group">
										<label class="form-control-label" for="input-state">Estado</label>
										<input type="text" id="input-state" v-bind:class="{'is-invalid': errors.state}"  maxlength="2" class="form-control" placeholder="Estado" v-model="state" required="false" >
										<div class="invalid-feedback" v-if="errors.state">
											<p>{{ errors.state[0] }}</p>
										</div>
									</div>
								</div>

								<!-- logotipo -->
								<!-- <div class="col-md-12">
									<div class="form-group">
										<label class="form-control-label" for="input-logotipo">Logotipo</label>
										<div class="custom-file">
											<input type="file" class="custom-file-input" id="logotipo" name="logotipo" accept="image/*" ref="logotipo" lang="pt" v-on:change="handleFilelogotipoUpload()">
											<label class="custom-file-label" for="logotipo">{{filelogotipoName}}</label>
										</div>
										<div class="invalid-feedback" v-if="errors.logotipo">
											<p>{{ errors.logotipo[0] }}</p>
										</div>
									</div>
								</div> -->

							</div>
						</div>

					</div>
				</div>
			</div>

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		data() {
			return {
				corporate_name: "",
				cnpj: "",
				address: "",
				number: "",
				complement: "",
				neighborhood: "",
				city: "",
				state: "",
				zip_code: "",
				logotipo: "",
				filelogotipoName: "Selecione o arquivo",
				errors: [],
				message: "",
				loading: false,
				fullPage: true
			}
		},
		mounted() {
		},
		methods: {
			handleFilelogotipoUpload(){
				this.logotipo = this.$refs.logotipo.files[0];
				this.filelogotipoName = this.$refs.logotipo.files[0].name;
			},
			newData() {
				let _this = this;
				_this.errors = [];
				_this.message = "";
				_this.loading = true;
				let dataInfo = {
					"corporate_name" : _this.corporate_name,
					"cnpj" : _this.cnpj,
					"address" : _this.address,
					"number" : _this.number,
					"complement" : _this.complement,
					"neighborhood" : _this.neighborhood,
					"city" : _this.city,
					"state" : _this.state,
					"zip_code" : _this.zip_code,
					"logotipo" : _this.logotipo,
					"last": ""
				};
				axios.post("/admin/companies", dataInfo).then((res) => {

					_this.loading = false;
					_this.resetForm();

					//call function in ApiService to validate success
					_this._api_validateSuccess(res,true);

				}).catch((err) => {

					_this.loading = false;

					if (err.response.data.errors){
						//set errors
						_this.errors = err.response.data.errors;
					}

					//call function in ApiService to validate error
					_this._api_validateError(err);

				});
			},
			resetForm() {
				this.corporate_name = '';
				this.cnpj = '';
				this.address = '';
				this.number = '';
				this.complement = '';
				this.neighborhood = '';
				this.city = '';
				this.state = '';
				this.zip_code = '';
				this.logotipo = '';

					this.errors = [];

			},
			searchCEP(){
				var cep = this.zip_code.replace('.','');
				cep = cep.replace('-','');
				axios.get("https://viacep.com.br/ws/"+cep).then((res) => {
					this.address = res.logradouro;
					this.neighborhood = res.bairro;
					this.city = res.localidade;
					this.state = res.uf;
				});
			},
			searchCNPJ(){
				let _this = this;
				let dataInfo = {
					"cnpj" : _this.cnpj
				};
				axios.post("/admin/search/cnpj", dataInfo).then((res) => {

					//apply data
					_this.corporate_name = res.data.data.nome;
					_this.fantasy_name = res.data.data.fantasia;
					_this.cep = res.data.data.cep;
					_this.address = res.data.data.logradouro;
					_this.number = res.data.data.numero;
					_this.neighborhood = res.data.data.bairro;
					_this.city = res.data.data.municipio;
					_this.state = res.data.data.uf;
					_this.phone = res.data.data.telefone;
					_this.email = res.data.data.email;
					
				}).catch((err) => {});
			}
		}
	}
</script>
