<template>
	<div>

		<!-- summary -->
		<div class="row">
      <div class="col-lg-4 col-md-4 col-sm-4 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-info">
            <i class="fas fa-university"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Saldo Atual</h4>
            </div>
            <div class="card-body">
              R$ {{ formatPrice(this.summary.balance) }}
            </div>
          </div>
        </div>
      </div>
      <div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-success">
            <i class="fas fa-arrow-up"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Receitas (Total)</h4>
            </div>
            <div class="card-body">
              R$ {{ formatPrice(this.summary.revenues) }}
            </div>
          </div>
        </div>
      </div>
			<div class="col-lg-4 col-md-6 col-sm-6 col-12">
        <div class="card card-statistic-1">
          <div class="card-icon bg-danger">
            <i class="fas fa-arrow-down"></i>
          </div>
          <div class="card-wrap">
            <div class="card-header">
              <h4>Despesas (Total)</h4>
            </div>
            <div class="card-body">
              R$ {{ formatPrice(this.summary.expenses) }}
            </div>
          </div>
        </div>
      </div>
    </div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Movimentações cadastradas</p>
				<a href="#" style="margin-left: 15px;" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalMovementsRevenues"><i class="fas fa-arrow-up"></i>&nbsp;Nova Receita</a>
				<a href="#" @click="resetForm()" class="btn btn-outline-danger float-right" data-toggle="modal" data-target="#modalMovementsExpenses"><i class="fas fa-arrow-down"></i>&nbsp;Nova Despesa</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="movementsItems.length"
						:columns="tableColumns"
						:rows="movementsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_type'">
								<i class="fas fa-arrow-up text-success" v-if="props.row.type === 0"></i>
								<i class="fas fa-arrow-down text-danger" v-if="props.row.type === 1"></i>
							</span>
							<span v-if="props.column.field == '_status'">
								<div v-if="props.row.status === 0" class="text-warning">Pendente</div>
								<div v-if="props.row.status === 1 && props.row.type === 0" class="text-success">Recebido</div>
								<div v-if="props.row.status === 1 && props.row.type === 1" class="text-success">Pago</div>
							</span>
							<span v-if="props.column.field == '_value'">
								R$ {{ formatPrice(props.row.value) }}
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum movimentações cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal revenues -->
			<div class="modal fade" id="modalMovementsRevenues" tabindex="-1" aria-labelledby="modalMovementsRevenuesLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalMovementsRevenuesLabel">{{movementsRevenuesTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveDataRevenue">

								<div class="row">

									<!-- account_id -->
									<div class="col-md-4">
										<div class="form-group">
											<label class="form-control-label" for="input-account_id">Selecione a Conta</label>
											<v-select :options="accountsOptions" label="name" :reduce="name => name.id" v-model="movementRevenue.account_id"></v-select>
											<div class="invalid-feedback" v-if="errors.account_id">
												<p>{{ errors.account_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- value -->
									<div class="col-md-2">
										<div class="form-group">
											<label class="form-control-label" for="input-value">Valor</label>
											<div class="input-group mb-3">
												<div class="input-group-prepend">
													<span class="input-group-text">R$</span>
												</div>
												<money v-model="movementRevenue.value" v-bind="money" class="form-control"></money>
											</div>
										</div>
									</div>

									<!-- due_date -->
									<div class="col-md-3">
										<div class="form-group">
											<label class="form-control-label" for="input-due_date">Vencimento</label>
											<input type="date" id="input-due_date" v-bind:class="{'is-invalid': errors.due_date}" class="form-control" placeholder="" v-model="movementRevenue.due_date">
											<div class="invalid-feedback" v-if="errors.due_date">
												<p>{{ errors.due_date[0] }}</p>
											</div>
										</div>
									</div>

									<!-- type -->
									<div class="col-md-3">
										<div class="form-group">
											<label class="form-control-label" for="input-repetition">Repetição</label>
											<!-- <input repetition="text" id="input-repetition" v-bind:class="{'is-invalid': errors.repetition}"   class="form-control" placeholder="repetition" v-model="movementRevenue.repetition" > -->
											<v-select :options="repetitionOptions" v-model="movementRevenue.repetition" :reduce="label => label.id"></v-select>
											<div class="invalid-feedback" v-if="errors.repetition">
												<p>{{ errors.repetition[0] }}</p>
											</div>
										</div>
									</div>
							
									<!-- description -->
									<div class="col-md-8">
										<div class="form-group">
											<label class="form-control-label" for="input-description">Descrição</label>
											<input type="text" id="input-description" v-bind:class="{'is-invalid': errors.description}"   class="form-control" placeholder="" v-model="movementRevenue.description" >
											<div class="invalid-feedback" v-if="errors.description">
												<p>{{ errors.description[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- document_number -->
									<div class="col-md-4">
										<div class="form-group">
											<label class="form-control-label" for="input-document_number">Número do Documento</label>
											<input type="text" id="input-document_number" v-bind:class="{'is-invalid': errors.document_number}" class="form-control" placeholder="" v-model="movementRevenue.document_number">
											<div class="invalid-feedback" v-if="errors.document_number">
												<p>{{ errors.document_number[0] }}</p>
											</div>
										</div>
									</div>

									<!-- company_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-company_id">Selecione o Centro de Custo</label>
											<v-select :options="companiesOptions" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="movementRevenue.company_id"></v-select>
											<div class="invalid-feedback" v-if="errors.company_id">
												<p>{{ errors.company_id[0] }}</p>
											</div>
										</div>
									</div>
							
									<!-- category_id -->
									<div class="col-md-3">
										<div class="form-group">
											<label class="form-control-label" for="input-category_id">Selecione a Categoria</label>
											<v-select :options="categoriesOptions" label="name" :reduce="name => name.id" v-model="movementRevenue.category_id" @input="validateSubcategories('revenue')"></v-select>
											<div class="invalid-feedback" v-if="errors.category_id">
												<p>{{ errors.category_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- subcategory_id -->
									<div class="col-md-3">
										<div class="form-group">
											<label class="form-control-label" for="input-subcategory_id">Selecione a Subcategoria</label>
											<v-select :options="subcategoriesOptions" label="name" :reduce="name => name.id" v-model="movementRevenue.subcategory_id"></v-select>
											<div class="invalid-feedback" v-if="errors.subcategory_id">
												<p>{{ errors.subcategory_id[0] }}</p>
											</div>
										</div>
									</div>
							
									<!-- product_id -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-product_id">Selecione o Produto</label>
											<v-select :options="productsOptions" label="name" :reduce="name => name.id" v-model="movementRevenue.product_id"></v-select>
											<div class="invalid-feedback" v-if="errors.product_id">
												<p>{{ errors.product_id[0] }}</p>
											</div>
										</div>
									</div>
							
									<!-- client_id -->
									<div class="col-md-8">
										<div class="form-group">
											<label class="form-control-label" for="input-client_id">Selecione o Cliente</label>
											<v-select :options="clientsOptions" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="movementRevenue.client_id"></v-select>
											<div class="invalid-feedback" v-if="errors.client_id">
												<p>{{ errors.client_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- status -->
									<div class="col-md-4">
										<div class="form-group">
											<label class="form-control-label" for="input-status">Status</label>
											<!-- <input type="text" id="input-status" v-bind:class="{'is-invalid': errors.status}"   class="form-control" placeholder="status" v-model="movementRevenue.status" > -->
											<v-select :options="statusRevenuesOptions" v-model="movementRevenue.status" :reduce="label => label.id"></v-select>
											<div class="invalid-feedback" v-if="errors.status">
												<p>{{ errors.status[0] }}</p>
											</div>
										</div>
									</div>

									<!-- payment_date -->
									<div class="col-md-4" v-if="movementRevenue.status === 'received'">
										<div class="form-group">
											<label class="form-control-label" for="input-payment_date">Data de Recebimento</label>
											<input type="date" id="input-payment_date" v-bind:class="{'is-invalid': errors.payment_date}" class="form-control" placeholder="" v-model="movementRevenue.payment_date" required="true" >
											<div class="invalid-feedback" v-if="errors.payment_date">
												<p>{{ errors.payment_date[0] }}</p>
											</div>
										</div>
									</div>

									<!-- observations -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-observations">Observações</label>
											<textarea type="text" id="input-observations" style="height: 120px !important;" v-bind:class="{'is-invalid': errors.observations}" class="form-control" placeholder="" v-model="movementRevenue.observations"></textarea>
											<div class="invalid-feedback" v-if="errors.observations">
												<p>{{ errors.observations[0] }}</p>
											</div>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			<!-- modal expenses -->
			<div class="modal fade" id="modalMovementsExpenses" tabindex="-1" aria-labelledby="modalMovementsExpensesLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered modal-xl">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalMovementsExpensesLabel">{{movementsRevenuesTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveDataExpense">

								<div class="row">

									<!-- source -->
									<div class="col-md-2">
										<div class="form-group">
											<label class="form-control-label" for="input-account_id">Tipo de Despesa</label>
											<v-select :options="sourceOptions" label="label" :reduce="label => label.id" v-model="movementExpense.source" @input="movementExpense.card_id = null; movementExpense.account_id = null;"></v-select>
										</div>
									</div>

									<!-- account_id -->
									<div class="col-md-4" v-if="movementExpense.source === 0">
										<div class="form-group">
											<label class="form-control-label" for="input-account_id">Selecione a Conta</label>
											<v-select :options="accountsOptions" label="name" :reduce="name => name.id" v-model="movementExpense.account_id"></v-select>
											<div class="invalid-feedback" v-if="errors.account_id">
												<p>{{ errors.account_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- card -->
									<div class="col-md-4" v-if="movementExpense.source === 1">
										<div class="form-group">
											<label class="form-control-label" for="input-card_id">Selecione o Cartão</label>
											<v-select :options="cardOptions" label="name" :reduce="name => name.id" v-model="movementExpense.card_id"></v-select>
											<div class="invalid-feedback" v-if="errors.card_id">
												<p>{{ errors.card_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- value -->
									<div class="col-md-2">
										<div class="form-group">
											<label class="form-control-label" for="input-value">Valor</label>
											<div class="input-group mb-3">
												<div class="input-group-prepend">
													<span class="input-group-text">R$</span>
												</div>
												<money v-model="movementExpense.value" v-bind="money" class="form-control"></money>
											</div>
										</div>
									</div>

									<!-- due_date -->
									<div class="col-md-2">
										<div class="form-group">
											<label class="form-control-label" for="input-due_date">Vencimento</label>
											<input type="date" id="input-due_date" v-bind:class="{'is-invalid': errors.due_date}" class="form-control" placeholder="" v-model="movementExpense.due_date">
											<div class="invalid-feedback" v-if="errors.due_date">
												<p>{{ errors.due_date[0] }}</p>
											</div>
										</div>
									</div>

									<!-- type -->
									<div class="col-md-2">
										<div class="form-group">
											<label class="form-control-label" for="input-repetition">Repetição</label>
											<!-- <input repetition="text" id="input-repetition" v-bind:class="{'is-invalid': errors.repetition}"   class="form-control" placeholder="repetition" v-model="movementExpense.repetition" > -->
											<v-select :options="repetitionOptions" v-model="movementExpense.repetition" :reduce="label => label.id"></v-select>
											<div class="invalid-feedback" v-if="errors.repetition">
												<p>{{ errors.repetition[0] }}</p>
											</div>
										</div>
									</div>

								</div>

								<!-- repetition options -->
								<!-- <div class="row" v-if="movementExpense.repetition === 'repetition'">

									
									<div class="col-md-4">
										<div class="form-group">
											<label class="form-control-label" for="input-repetition_frequency">repetition_frequency</label>
											<input type="text" id="input-repetition_frequency" v-bind:class="{'is-invalid': errors.repetition_frequency}"  maxlength="20" class="form-control" placeholder="repetition_frequency" v-model="movementRevenue.repetition_frequency" required="true" >
											<div class="invalid-feedback" v-if="errors.repetition_frequency">
												<p>{{ errors.repetition_frequency[0] }}</p>
											</div>
										</div>
									</div>
									

									
									<div class="col-md-4">
										<div class="form-group">
											<label class="form-control-label" for="input-repetition_installments">repetition_installments</label>
											<input type="text" id="input-repetition_installments" v-bind:class="{'is-invalid': errors.repetition_installments}"   class="form-control" placeholder="repetition_installments" v-model="movementRevenue.repetition_installments" required="true" >
											<div class="invalid-feedback" v-if="errors.repetition_installments">
												<p>{{ errors.repetition_installments[0] }}</p>
											</div>
										</div>
									</div>
									

									
									<div class="col-md-4">
										<div class="form-group">
											<label class="form-control-label" for="input-repetition_actual">repetition_actual</label>
											<input type="text" id="input-repetition_actual" v-bind:class="{'is-invalid': errors.repetition_actual}"   class="form-control" placeholder="repetition_actual" v-model="movementRevenue.repetition_actual" required="true" >
											<div class="invalid-feedback" v-if="errors.repetition_actual">
												<p>{{ errors.repetition_actual[0] }}</p>
											</div>
										</div>
									</div>

								</div> -->

								<div class="row">
							
									<!-- description -->
									<div class="col-md-8">
										<div class="form-group">
											<label class="form-control-label" for="input-description">Descrição</label>
											<input type="text" id="input-description" v-bind:class="{'is-invalid': errors.description}"   class="form-control" placeholder="" v-model="movementExpense.description" >
											<div class="invalid-feedback" v-if="errors.description">
												<p>{{ errors.description[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- document_number -->
									<div class="col-md-4">
										<div class="form-group">
											<label class="form-control-label" for="input-document_number">Número do Documento</label>
											<input type="text" id="input-document_number" v-bind:class="{'is-invalid': errors.document_number}" class="form-control" placeholder="" v-model="movementExpense.document_number">
											<div class="invalid-feedback" v-if="errors.document_number">
												<p>{{ errors.document_number[0] }}</p>
											</div>
										</div>
									</div>

									<!-- company_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-company_id">Selecione o Centro de Custo</label>
											<v-select :options="companiesOptions" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="movementExpense.company_id"></v-select>
											<div class="invalid-feedback" v-if="errors.company_id">
												<p>{{ errors.company_id[0] }}</p>
											</div>
										</div>
									</div>
							
									<!-- category_id -->
									<div class="col-md-3">
										<div class="form-group">
											<label class="form-control-label" for="input-category_id">Selecione a Categoria</label>
											<v-select :options="categoriesOptions" label="name" :reduce="name => name.id" v-model="movementExpense.category_id" @input="validateSubcategories('expense')"></v-select>
											<div class="invalid-feedback" v-if="errors.category_id">
												<p>{{ errors.category_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- subcategory_id -->
									<div class="col-md-3">
										<div class="form-group">
											<label class="form-control-label" for="input-subcategory_id">Selecione a Subcategoria</label>
											<v-select :options="subcategoriesOptions" label="name" :reduce="name => name.id" v-model="movementExpense.subcategory_id"></v-select>
											<div class="invalid-feedback" v-if="errors.subcategory_id">
												<p>{{ errors.subcategory_id[0] }}</p>
											</div>
										</div>
									</div>
							
									<!-- product_id -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-product_id">Selecione o Produto</label>
											<v-select :options="productsOptions" label="name" :reduce="name => name.id" v-model="movementExpense.product_id"></v-select>
											<div class="invalid-feedback" v-if="errors.product_id">
												<p>{{ errors.product_id[0] }}</p>
											</div>
										</div>
									</div>
							
									<!-- supplier_id -->
									<div class="col-md-8">
										<div class="form-group">
											<label class="form-control-label" for="input-client_id">Selecione o Fornecedor</label>
											<v-select :options="supllierOptions" label="corporate_name" :reduce="corporate_name => corporate_name.id" v-model="movementExpense.supplier_id"></v-select>
											<div class="invalid-feedback" v-if="errors.supplier_id">
												<p>{{ errors.supplier_id[0] }}</p>
											</div>
										</div>
									</div>

									<!-- status -->
									<div class="col-md-4">
										<div class="form-group">
											<label class="form-control-label" for="input-status">Status</label>
											<v-select :options="statusExpensesOptions" v-model="movementExpense.status" :reduce="label => label.id"></v-select>
											<div class="invalid-feedback" v-if="errors.status">
												<p>{{ errors.status[0] }}</p>
											</div>
										</div>
									</div>

									<!-- payment_date -->
									<div class="col-md-4" v-if="movementExpense.status === 'received'">
										<div class="form-group">
											<label class="form-control-label" for="input-payment_date">Data de Pagamento</label>
											<input type="date" id="input-payment_date" v-bind:class="{'is-invalid': errors.payment_date}" class="form-control" placeholder="" v-model="movementExpense.payment_date" required="true" >
											<div class="invalid-feedback" v-if="errors.payment_date">
												<p>{{ errors.payment_date[0] }}</p>
											</div>
										</div>
									</div>

									<!-- observations -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-observations">Observações</label>
											<textarea type="text" id="input-observations" style="height: 120px !important;" v-bind:class="{'is-invalid': errors.observations}" class="form-control" placeholder="" v-model="movementExpense.observations"></textarea>
											<div class="invalid-feedback" v-if="errors.observations">
												<p>{{ errors.observations[0] }}</p>
											</div>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>

			<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				accountsOptions: [],
				companiesOptions: [],
				categoriesOptions: [],
				subcategoriesOptions: [],
				subcategoriesTemp: [],
				cardOptions: [],
				productsOptions: [],
				clientsOptions: [],
				supllierOptions: [],
				money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false
        },
				movementsRevenuesTitle: "Nova Receita",
				movementsExpensesTitle: "Nova Despesa",
				movementExpense: {
					source: 0,
					status: 0,
					value: 0,
					type: 0,
					due_date: null,
					payment_date: null,
					repetition: 'unique',
					repetition_frequency: null,
					repetition_installments: null,
					repetition_actual: null,
					description: null,
					document_number: null,
					observations: null,
					account_id: null,
					company_id: null,
					category_id: null,
					subcategory_id: null,
					card_id: null,
					product_id: null,
					client_id: null,
					supplier_id: null,
				},
				movementRevenue: {
					status: 0,
					value: 0,
					type: 1,
					due_date: null,
					payment_date: null,
					repetition: 'unique',
					repetition_frequency: null,
					repetition_installments: null,
					repetition_actual: null,
					description: null,
					document_number: null,
					observations: null,
					account_id: null,
					company_id: null,
					category_id: null,
					subcategory_id: null,
					card_id: null,
					product_id: null,
					client_id: null,
					supplier_id: null,
				},
				summary: {
					balance: 0,
					expenses: 0,
					revenues: 0
				},
				isEditMovements: false,
				movementsID: "",
				movementsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				sourceOptions: [{
					id: 0,
					label: 'Comum'
				},{
					id: 1,
					label: 'Cartão'
				}],
				statusExpensesOptions: [{
					id: 0,
					label: 'Aguardando Pagamento'
				},{
					id: 1,
					label: 'Pago'
				}],
				statusRevenuesOptions: [{
					id: 0,
					label: 'Aguardando Recebimento'
				},{
					id: 1,
					label: 'Recebido'
				}],
				repetitionOptions: [{
					id: 'unique',
					label: 'Única'
				}],
				// repetitionOptions: [{
				// 	id: 'unique',
				// 	label: 'Única'
				// },{
				// 	id: 'fix',
				// 	label: 'Fixa (mensal)'
				// },{
				// 	id: 'repetition',
				// 	label: 'Repetir'
				// }],
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: '',
						field: '_type',
					},{
						label: 'Status',
						field: '_status',
					},{
						label: 'Valor',
						field: '_value',
					},{
						label: 'Vencimento',
						field: 'due_date',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd",
						dateOutputFormat: 'dd/MM/yyyy'
					},{
						label: 'Descrição',
						field: 'description',
					},{
						label: 'Centro de Custo',
						field: 'companies.corporate_name',
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadAccounts();
			this.loadCompanies();
			this.loadCategories();
			this.loadSubcategories();
			this.loadCards();
			this.loadProducts();
			this.loadClients();
			this.loadSuppliers();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/movements").then((res) => {
					_this.movementsItems = _this.movementsItems.concat(res.data.movements);
					_this.summary.expenses = parseFloat(res.data.expenses);
					_this.summary.revenues = parseFloat(res.data.revenues);
					_this.summary.balance = parseFloat(res.data.revenues) - parseFloat(res.data.expenses);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveDataExpense() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditMovements == true){

					let dataInfo = {
						"status" : _this.movementExpense.status,
						"value" : _this.movementExpense.value,
						"type" : _this.movementExpense.type,
						"due_date" : _this.movementExpense.due_date,
						"payment_date" : _this.movementExpense.payment_date,
						"repetition" : _this.movementExpense.repetition,
						"repetition_frequency" : _this.movementExpense.repetition_frequency,
						"repetition_installments" : _this.movementExpense.repetition_installments,
						"repetition_actual" : _this.movementExpense.repetition_actual,
						"description" : _this.movementExpense.description,
						"document_number" : _this.movementExpense.document_number,
						"observations" : _this.movementExpense.observations,
						"account_id" : _this.movementExpense.account_id,
						"company_id" : _this.movementExpense.company_id,
						"category_id" : _this.movementExpense.category_id,
						"subcategory_id" : _this.movementExpense.subcategory_id,
						"card_id" : _this.movementExpense.card_id,
						"product_id" : _this.movementExpense.product_id,
						"client_id" : _this.movementExpense.client_id,
						"supplier_id" : _this.movementExpense.supplier_id,
						"_method": "PATCH"
					};
					axios.post("/admin/movements/"+_this.movementsID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalMovementsExpense').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"status" : _this.movementExpense.status,
						"value" : _this.movementExpense.value,
						"type" : _this.movementExpense.type,
						"due_date" : _this.movementExpense.due_date,
						"payment_date" : _this.movementExpense.payment_date,
						"repetition" : _this.movementExpense.repetition,
						"repetition_frequency" : _this.movementExpense.repetition_frequency,
						"repetition_installments" : _this.movementExpense.repetition_installments,
						"repetition_actual" : _this.movementExpense.repetition_actual,
						"description" : _this.movementExpense.description,
						"document_number" : _this.movementExpense.document_number,
						"observations" : _this.movementExpense.observations,
						"account_id" : _this.movementExpense.account_id,
						"company_id" : _this.movementExpense.company_id,
						"category_id" : _this.movementExpense.category_id,
						"subcategory_id" : _this.movementExpense.subcategory_id,
						"card_id" : _this.movementExpense.card_id,
						"product_id" : _this.movementExpense.product_id,
						"client_id" : _this.movementExpense.client_id,
						"supplier_id" : _this.movementExpense.supplier_id,
						"last": ""
					};
					axios.post("/admin/movements", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.movementsItems.push(res.data.data);
						$('#modalMovementsExpense').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			saveDataRevenue() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditMovements == true){

					let dataInfo = {
						"status" : _this.movementRevenue.status,
						"value" : _this.movementRevenue.value,
						"type" : _this.movementRevenue.type,
						"due_date" : _this.movementRevenue.due_date,
						"payment_date" : _this.movementRevenue.payment_date,
						"repetition" : _this.movementRevenue.repetition,
						"repetition_frequency" : _this.movementRevenue.repetition_frequency,
						"repetition_installments" : _this.movementRevenue.repetition_installments,
						"repetition_actual" : _this.movementRevenue.repetition_actual,
						"description" : _this.movementRevenue.description,
						"document_number" : _this.movementRevenue.document_number,
						"observations" : _this.movementRevenue.observations,
						"account_id" : _this.movementRevenue.account_id,
						"company_id" : _this.movementRevenue.company_id,
						"category_id" : _this.movementRevenue.category_id,
						"subcategory_id" : _this.movementRevenue.subcategory_id,
						"card_id" : _this.movementRevenue.card_id,
						"product_id" : _this.movementRevenue.product_id,
						"client_id" : _this.movementRevenue.client_id,
						"supplier_id" : _this.movementRevenue.supplier_id,
						"_method": "PATCH"
					};
					axios.post("/admin/movements/"+_this.movementsID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalMovementsRevenues').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"status" : _this.movementRevenue.status,
						"value" : _this.movementRevenue.value,
						"type" : _this.movementRevenue.type,
						"due_date" : _this.movementRevenue.due_date,
						"payment_date" : _this.movementRevenue.payment_date,
						"repetition" : _this.movementRevenue.repetition,
						"repetition_frequency" : _this.movementRevenue.repetition_frequency,
						"repetition_installments" : _this.movementRevenue.repetition_installments,
						"repetition_actual" : _this.movementRevenue.repetition_actual,
						"description" : _this.movementRevenue.description,
						"document_number" : _this.movementRevenue.document_number,
						"observations" : _this.movementRevenue.observations,
						"account_id" : _this.movementRevenue.account_id,
						"company_id" : _this.movementRevenue.company_id,
						"category_id" : _this.movementRevenue.category_id,
						"subcategory_id" : _this.movementRevenue.subcategory_id,
						"card_id" : _this.movementRevenue.card_id,
						"product_id" : _this.movementRevenue.product_id,
						"client_id" : _this.movementRevenue.client_id,
						"supplier_id" : _this.movementRevenue.supplier_id,
						"last": ""
					};
					axios.post("/admin/movements", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.movementsItems.push(res.data.data);
						$('#modalMovementsRevenues').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir movements",
					text: "Tem certeza que deseja excluir esse(a) movements?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/movements/"+ID).then((res) => {

							_this.movements.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.isEditMovements = true;
				this.movementsID = item.id;

				if(item.type == 0){

					this.movementRevenue = item;
					this.movementsTitle = "Editar Receita";

					$("#modalMovementsRevenues").modal('show');

				} else {

					this.movementExpense = item;
					this.movementsTitle = "Editar Despesa";

					$("#modalMovementsExpenses").modal('show');


				}

			},
			loadAccounts() {
				let _this = this;
				axios.get("/admin/accounts").then((res) => {
					_this.accountsOptions = _this.accountsOptions.concat(res.data);
				}).catch((err) => {
				});
			},
			loadCompanies() {
				let _this = this;
				axios.get("/admin/companies").then((res) => {
					_this.companiesOptions = _this.companiesOptions.concat(res.data);
				}).catch((err) => {
				});
			},
			loadCategories() {
				let _this = this;
				axios.get("/admin/categories").then((res) => {
					_this.categoriesOptions = _this.categoriesOptions.concat(res.data);
				}).catch((err) => {
				});
			},
			loadSubcategories() {
				let _this = this;
				axios.get("/admin/subcategories").then((res) => {
					_this.subcategoriesTemp = _this.subcategoriesTemp.concat(res.data);
				}).catch((err) => {
				});
			},
			loadCards() {
				let _this = this;
				axios.get("/admin/cards").then((res) => {
					_this.cardOptions = _this.cardOptions.concat(res.data);
				}).catch((err) => {
				});
			},
			loadProducts() {
				let _this = this;
				axios.get("/admin/products").then((res) => {
					_this.productsOptions = _this.productsOptions.concat(res.data);
				}).catch((err) => {
				});
			},
			loadClients() {
				let _this = this;
				axios.get("/admin/clients").then((res) => {
					_this.clientsOptions = _this.clientsOptions.concat(res.data);
				}).catch((err) => {
				});
			},
			loadSuppliers() {
				let _this = this;
				axios.get("/admin/suppliers").then((res) => {
					_this.supllierOptions = _this.supllierOptions.concat(res.data);
				}).catch((err) => {
				});
			},
			resetForm(){
				this.movementExpense = {
					source: 0,
					status: 0,
					value: 0,
					type: 1,
					due_date: null,
					payment_date: null,
					repetition: 'unique',
					repetition_frequency: null,
					repetition_installments: null,
					repetition_actual: null,
					description: null,
					document_number: null,
					observations: null,
					account_id: null,
					company_id: null,
					category_id: null,
					subcategory_id: null,
					card_id: null,
					product_id: null,
					client_id: null,
					supplier_id: null,
				};

				this.movementRevenue = {
					status: 0,
					value: 0,
					type: 0,
					due_date: null,
					payment_date: null,
					repetition: 'unique',
					repetition_frequency: null,
					repetition_installments: null,
					repetition_actual: null,
					description: null,
					document_number: null,
					observations: null,
					account_id: null,
					company_id: null,
					category_id: null,
					subcategory_id: null,
					card_id: null,
					product_id: null,
					client_id: null,
					supplier_id: null,
				};

				this.errors = [];
				this.isEditMovements = false;
				this.movementsID = "";
				this.movementsExpensesTitle = "Nova Despesa";
				this.movementsRevenuesTitle = "Nova Receita";

			},
			validateSubcategories(origin){
				let _this = this;
				_this.subcategoriesOptions = [];

				if(origin == 'revenue'){

					_this.subcategoriesTemp.map((rowSubcategory) => {
						if(rowSubcategory.category_id == _this.movementRevenue.category_id){
							_this.subcategoriesOptions.push(rowSubcategory);
						}
					});

				} else {

					_this.subcategoriesTemp.map((rowSubcategory) => {
						if(rowSubcategory.category_id == _this.movementExpense.category_id){
							_this.subcategoriesOptions.push(rowSubcategory);
						}
					});

				}

			},
			formatPrice(value) {
        let val = (value/1).toFixed(2).replace('.', ',')
        return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ".")
      }
		}
	}
</script>
