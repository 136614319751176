<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todas Subcategorias cadastradas</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalSubcategories"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="subcategoriesItems.length"
						:columns="tableColumns"
						:rows="subcategoriesItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_image'">
								<img :src="'/storage/subcategories/'+props.row.icon" width="32"/>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhuma subcategoria cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalSubcategories" tabindex="-1" aria-labelledby="modalSubcategoriesLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalSubcategoriesLabel">{{subcategoriesTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- name -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-name">Nome</label>
											<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}"   class="form-control" placeholder="Nome" v-model="subcategory.name" required="false" >
											<div class="invalid-feedback" v-if="errors.name">
												<p>{{ errors.name[0] }}</p>
											</div>
										</div>
									</div>

									<!-- category_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-category_id">Categoria</label>
											<v-select :options="categoriesOptions" label="name" :reduce="name => name.id" v-model="subcategory.category_id"></v-select>
											<div class="invalid-feedback" v-if="errors.category_id">
												<p>{{ errors.category_id[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- icon -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-icon">Ícone (não obrigatório)</label>
											<div class="custom-file">
												<input type="file" class="custom-file-input" id="icon" name="icon" accept="image/*" ref="icon" lang="pt" v-on:change="handleFileUpload()">
												<label class="custom-file-label" for="icon">{{fileIconName}}</label>
											</div>
											<div class="invalid-feedback" v-if="errors.icon">
												<p>{{ errors.icon[0] }}</p>
											</div>
											<img :src="selectedFile" v-if="selectedFile" style="width: 50%; margin: 10px 25%;"/>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				fileIconName: "Selecione o arquivo",
				categoriesOptions: [],
				selectedFile: null,
				subcategoriesTitle: "Nova Subcategoria",
				subcategory: {
					name: '',
					icon: '',
					category_id: '',
				},
				isEditSubcategories: false,
				subcategoriesID: "",
				subcategoriesItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: '',
						field: '_image',
						width: '80px'
					},{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Categoria',
						field: 'category.name',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadCategories();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/subcategories").then((res) => {
					_this.subcategoriesItems = _this.subcategoriesItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			handleFileUpload(){
				this.subcategory.icon = this.$refs.icon.files[0];
				this.fileIconName = this.$refs.icon.files[0].name;
				this.selectedFile = URL.createObjectURL(this.$refs.icon.files[0])
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditSubcategories == true){

					let formData = new FormData();
					formData.append("name", _this.subcategory.name);
					formData.append("category_id", _this.subcategory.category_id);
					formData.append("icon", _this.subcategory.icon);
					formData.append("_method", "PATCH");

					axios.post("/admin/subcategories/"+_this.subcategoriesID, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalSubcategories').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let formData = new FormData();
					formData.append("name", _this.subcategory.name);
					formData.append("category_id", _this.subcategory.category_id);
					formData.append("icon", _this.subcategory.icon);

					axios.post("/admin/subcategories", formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.subcategoriesItems.push(res.data.data);
						$('#modalSubcategories').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Subcategoria",
					text: "Tem certeza que deseja excluir essea Subcategoria?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/subcategories/"+ID).then((res) => {

							_this.subcategories.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.subcategory = item;
				this.isEditSubcategories = true;
				this.subcategoriesID = item.id;
				this.subcategoriesTitle = "Edit ";

				$("#modalSubcategories").modal('show');

			},
			loadCategories() {
				let _this = this;
				axios.get("/admin/categories").then((res) => {
					_this.categoriesOptions = _this.categoriesOptions.concat(res.data);
				}).catch((err) => {
				});
			},
			resetForm(){

				this.subcategory = {
					name: '',
					icon: '',
					category_id: '',
				};

				this.errors = [];
				this.isEditSubcategories = false;
				this.subcategoriesID = "";
				this.subcategoriesTitle = "Nova Subcategoria";

			}
		}
	}
</script>
