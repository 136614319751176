<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Cartão cadastrado</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalCards"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="cardsItems.length"
						:columns="tableColumns"
						:rows="cardsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum cartão cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalCards" tabindex="-1" aria-labelledby="modalCardsLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalCardsLabel">{{cardsTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- name -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-name">Nome</label>
											<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}"   class="form-control" placeholder="Nome" v-model="card.name" required="false" >
											<div class="invalid-feedback" v-if="errors.name">
												<p>{{ errors.name[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- limit -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-limit">Limite</label>
											<!-- <input type="number" id="input-limit" v-bind:class="{'is-invalid': errors.limit}"   class="form-control" placeholder="Limite" v-model="account.limit"> -->
											<money v-model="card.limit" v-bind="money" class="form-control"></money>
											<div class="invalid-feedback" v-if="errors.limit">
												<p>{{ errors.limit[0] }}</p>
											</div>
										</div>
									</div>

									<!-- due_date -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-due_date">Dia de Vencimento</label>
											<input type="text" id="input-due_date" v-bind:class="{'is-invalid': errors.due_date}"   class="form-control" placeholder="Data de Vencimento" v-model="card.due_date" required="false" >
											<div class="invalid-feedback" v-if="errors.due_date">
												<p>{{ errors.due_date[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- bank_id -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-bank">Banco</label>
											<!-- <input type="text" id="input-bank" v-bind:class="{'is-invalid': errors.bank}"   class="form-control" placeholder="Banco" v-model="bank_id"> -->
											<v-select :options="banksOptions" v-model="card.bank_id" label="name" :reduce="name => name.id">
												<template slot="option" slot-scope="option">
													<img :src="'http://127.0.0.1:8000/storage/banks/'+option.image" style="height: 16px; margin: 0 10px 0 0;"/>
													{{ option.name }}
												</template>
												<template slot="selected-option" slot-scope="option">
													<img :src="'http://127.0.0.1:8000/storage/banks/'+option.image" style="height: 16px; margin: 0 10px 0 0;"/>
													{{ option.name }}
												</template>
											</v-select>
											<div class="invalid-feedback" v-if="errors.bank">
												<p>{{ errors.bank[0] }}</p>
											</div>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				banksOptions: [],
				cardsTitle: "Novo Cartão",
				card: {
					name: '',
					limit: '',
					due_date: '',
					bank_id: '',
				},
				isEditCards: false,
				cardsID: "",
				cardsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false
        },
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Limite',
						field: 'limit',
					},{
						label: 'Dia de Vencimento',
						field: 'due_date',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadBanks();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/cards").then((res) => {
					_this.cardsItems = _this.cardsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditCards == true){

					let dataInfo = {
						"name" : _this.card.name,
						"limit" : _this.card.limit,
						"due_date" : _this.card.due_date,
						"bank_id" : _this.card.bank_id,
						"_method": "PATCH"
					};
					axios.post("/admin/cards/"+_this.cardsID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalCards').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"name" : _this.card.name,
						"limit" : _this.card.limit,
						"due_date" : _this.card.due_date,
						"bank_id" : _this.card.bank_id,
						"last": ""
					};
					axios.post("/admin/cards", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.cardsItems.push(res.data.data);
						$('#modalCards').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir cards",
					text: "Tem certeza que deseja excluir esse(a) cards?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/cards/"+ID).then((res) => {

							_this.cards.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.card = item;
				this.isEditCards = true;
				this.cardsID = item.id;
				this.cardsTitle = "Editar Cartão";

				$("#modalCards").modal('show');

			},
			loadBanks() {
				let _this = this;
				_this.banksOptions = [];
				axios.get("/admin/banks").then((res) => {
					_this.banksOptions = _this.banksOptions.concat(res.data);
				}).catch((err) => {
				});
			},
			resetForm(){

				this.card = {
					name: '',
					limit: '',
					due_date: '',
					bank_id: '',
				};

				this.errors = [];
				this.isEditCards = false;
				this.cardsID = "";
				this.cardsTitle = "Novo Cartão";

			}
		}
	}
</script>
