<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Bancos cadastrados</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalBanks"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="banksItems.length"
						:columns="tableColumns"
						:rows="banksItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_image'">
								<img :src="'/storage/banks/'+props.row.image" width="32"/>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhum bancos cadastrado!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalBanks" tabindex="-1" aria-labelledby="modalBanksLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalBanksLabel">{{banksTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- name -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-name">Nome</label>
											<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}"   class="form-control" placeholder="Nome" v-model="bank.name" required="false" >
											<div class="invalid-feedback" v-if="errors.name">
												<p>{{ errors.name[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- image -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-image">Imagem</label>
											<div class="custom-file">
												<input type="file" class="custom-file-input" id="image" name="image" accept="image/*" ref="image" lang="pt" v-on:change="handleFileUpload()">
												<label class="custom-file-label" for="image">{{fileImageName}}</label>
											</div>
											<div class="invalid-feedback" v-if="errors.image">
												<p>{{ errors.image[0] }}</p>
											</div>
											<img :src="selectedFile" v-if="selectedFile" style="width: 50%; margin: 10px 25%;"/>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				fileImageName: "Selecione o arquivo",
				banksTitle: "Novo Banco",
				bank: {
					name: '',
					image: null,
				},
				isEditBanks: false,
				banksID: "",
				banksItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				selectedFile: null,
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: '',
						field: '_image',
						width: '80px'
					},{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/banks").then((res) => {
					_this.banksItems = _this.banksItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			handleFileUpload(){
				this.bank.image = this.$refs.image.files[0];
				this.fileImageName = this.$refs.image.files[0].name;
				this.selectedFile = URL.createObjectURL(this.$refs.image.files[0])
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditBanks == true){

					let formData = new FormData();
					formData.append("name", _this.bank.name);
					formData.append("image", _this.bank.image);
					formData.append("_method", "PATCH");

					axios.post("/admin/banks/"+_this.banksID, formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalBanks').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let formData = new FormData();
					formData.append("name", _this.bank.name);
					formData.append("image", _this.bank.image);

					axios.post("/admin/banks", formData, {headers: {'Content-Type': 'multipart/form-data'}}).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.banksItems.push(res.data.data);
						$('#modalBanks').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Banco",
					text: "Tem certeza que deseja excluir esse Banco?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/banks/"+ID).then((res) => {

							_this.banks.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.bank = item;
				this.isEditBanks = true;
				this.banksID = item.id;
				this.banksTitle = "Editar Banco";

				$("#modalBanks").modal('show');

			},
			resetForm(){

				this.bank = {
					name: '',
					image: '',
				};

				this.errors = [];
				this.isEditBanks = false;
				this.banksID = "";
				this.banksTitle = "Novo Banco";

			}
		}
	}
</script>
