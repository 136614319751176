<template>
	<div>

		<div class="row section-top">
			<div class="col-md-12 pad0">
				<p class="section-lead">Lista de todos Contas cadastradas</p>
				<a href="#" @click="resetForm()" class="btn btn-outline-success float-right" data-toggle="modal" data-target="#modalAccounts"><i class="fas fa-plus"></i>&nbsp;Novo</a>
			</div>
		</div>

		<div class="row">
			<div class="col">

				<!-- table -->
				<div class="card p-4">
					<vue-good-table v-if="accountsItems.length"
						:columns="tableColumns"
						:rows="accountsItems"
						:pagination-options="tablePagination"
						:search-options="{
							enabled: true,
							placeholder: 'Buscar..',
						}"
						styleClass="vgt-table"
					>
						<template slot="table-row" slot-scope="props">
							<span v-if="props.column.field == '_type'">
								<div v-if="props.row.type === 'money'">Dinheiro</div>
								<div v-if="props.row.type === 'current_account'">Conta Corrente</div>
								<div v-if="props.row.type === 'investments'">Investimentos</div>
							</span>
							<span v-if="props.column.field == 'actions'">
								<div>
									<button class="btn btn-sm btn-outline-secondary" v-if="userCan.edit" @click="editData(props.row)" title="Editar"><i class="far fa-edit"></i></button>
									<button class="btn btn-sm btn-outline-danger" v-if="userCan.delete" @click="deleteData(props.row.id, props.row.originalIndex)" title="Deletar"><i class="far fa-trash-alt"></i></button>
								</div>
							</span>
							<span v-else>
								{{ props.formattedRow[props.column.field] }}
							</span>
						</template>
					</vue-good-table>
					<div v-else class="text-center p-3 text-muted">
						<h5>Nenhuma conta cadastrada!</h5>
					</div>
				</div>

				<div class="text-center p-4 text-muted" v-if="loading">
					<h5>Carregando...</h5>
					<p>Aguarde até que os dados sejam carregados...</p>
				</div>

			</div>

			<!-- modal -->
			<div class="modal fade" id="modalAccounts" tabindex="-1" aria-labelledby="modalAccountsLabel" aria-hidden="true">
				<div class="modal-dialog modal-dialog-centered">
					<div class="modal-content">
						<div class="modal-header">
							<h5 class="modal-title" id="modalAccountsLabel">{{accountsTitle}}</h5>
							<button type="button" class="close" data-dismiss="modal" aria-label="Close">
								<span aria-hidden="true">&times;</span>
							</button>
						</div>
						<div class="modal-body">
							<form v-on:submit.prevent="saveData">

								<div class="row">

									<!-- type -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-type">Tipo</label>
											<v-select :options="typeOptions" v-model="account.type" :reduce="label => label.id"></v-select>
											<div class="invalid-feedback" v-if="errors.type">
												<p>{{ errors.type[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- coin -->
									<div class="col-md-4">
										<div class="form-group">
											<label class="form-control-label" for="input-coin">Moeda</label>
											<!-- <v-select v-model="account.coin"></v-select> -->
											<input type="text" id="input-coin" v-bind:class="{'is-invalid': errors.coin}" :disabled="true" class="form-control" placeholder="" v-model="account.coin">
											<div class="invalid-feedback" v-if="errors.coin">
												<p>{{ errors.coin[0] }}</p>
											</div>
										</div>
									</div>

									<!-- coin_symbol -->
									<div class="col-md-2">
										<div class="form-group">
											<label class="form-control-label" for="input-coin_symbol">Símbolo</label>
											<input type="text" id="input-coin_symbol" v-bind:class="{'is-invalid': errors.coin_symbol}" :disabled="true" class="form-control" placeholder="R$" v-model="account.coin_symbol">
											<div class="invalid-feedback" v-if="errors.coin_symbol">
												<p>{{ errors.coin_symbol[0] }}</p>
											</div>
										</div>
									</div>
									

									<!-- name -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-name">Nome</label>
											<input type="text" id="input-name" v-bind:class="{'is-invalid': errors.name}"   class="form-control" placeholder="Nome" v-model="account.name" required="true" >
											<div class="invalid-feedback" v-if="errors.name">
												<p>{{ errors.name[0] }}</p>
											</div>
										</div>
									</div>

									<div class="col-md-12" style="margin: 25px 0 15px 0;">
										<h5 style="font-size: 12px;">Dados Bancários (opcional)</h5>
									</div>
									
									<!-- bank -->
									<div class="col-md-12">
										<div class="form-group">
											<label class="form-control-label" for="input-bank">Banco</label>
											<!-- <input type="text" id="input-bank" v-bind:class="{'is-invalid': errors.bank}"   class="form-control" placeholder="Banco" v-model="account.bank"> -->
											<v-select :options="banksOptions" v-model="account.bank" label="name" :reduce="name => name.id">
												<template slot="option" slot-scope="option">
													<img :src="'http://127.0.0.1:8000/storage/banks/'+option.image" style="height: 16px; margin: 0 10px 0 0;"/>
													{{ option.name }}
												</template>
												<template slot="selected-option" slot-scope="option">
													<img :src="'http://127.0.0.1:8000/storage/banks/'+option.image" style="height: 16px; margin: 0 10px 0 0;"/>
													{{ option.name }}
												</template>
											</v-select>
											<div class="invalid-feedback" v-if="errors.bank">
												<p>{{ errors.bank[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- agency -->
									<div class="col-md-5">
										<div class="form-group">
											<label class="form-control-label" for="input-agency">Agência</label>
											<input type="text" id="input-agency" v-bind:class="{'is-invalid': errors.agency}"  maxlength="20" class="form-control" placeholder="Agência" v-model="account.agency">
											<div class="invalid-feedback" v-if="errors.agency">
												<p>{{ errors.agency[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- number -->
									<div class="col-md-7">
										<div class="form-group">
											<label class="form-control-label" for="input-number">Número da Conta</label>
											<input type="text" id="input-number" v-bind:class="{'is-invalid': errors.number}"  maxlength="30" class="form-control" placeholder="Número da Conta" v-model="account.number">
											<div class="invalid-feedback" v-if="errors.number">
												<p>{{ errors.number[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- limit -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-limit">Limite</label>
											<!-- <input type="number" id="input-limit" v-bind:class="{'is-invalid': errors.limit}"   class="form-control" placeholder="Limite" v-model="account.limit"> -->
											<money v-model="account.limit" v-bind="money" class="form-control"></money>
											<div class="invalid-feedback" v-if="errors.limit">
												<p>{{ errors.limit[0] }}</p>
											</div>
										</div>
									</div>

									<!-- initial_balance -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-initial_balance">Saldo Inicial</label>
											<!-- <input type="number" id="input-initial_balance" v-bind:class="{'is-invalid': errors.initial_balance}"   class="form-control" placeholder="Saldo Inicial" v-model="account.initial_balance"> -->
											<money v-model="account.initial_balance" v-bind="money" class="form-control"></money>
											<div class="invalid-feedback" v-if="errors.initial_balance">
												<p>{{ errors.initial_balance[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- contact -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-contact">Contato</label>
											<input type="text" id="input-contact" v-bind:class="{'is-invalid': errors.contact}"   class="form-control" placeholder="Contato" v-model="account.contact">
											<div class="invalid-feedback" v-if="errors.contact">
												<p>{{ errors.contact[0] }}</p>
											</div>
										</div>
									</div>
									
									<!-- phone -->
									<div class="col-md-6">
										<div class="form-group">
											<label class="form-control-label" for="input-phone">Telefone</label>
											<input type="text" id="input-phone" v-bind:class="{'is-invalid': errors.phone}"  maxlength="30" class="form-control" placeholder="Telefone" v-model="account.phone"v-mask="'(##) #####-####'">
											<div class="invalid-feedback" v-if="errors.phone">
												<p>{{ errors.phone[0] }}</p>
											</div>
										</div>
									</div>
							
								</div>

								<div class="text-right">
									<button type="submit" class="btn btn-success">SALVAR</button>
								</div>
							</form>
						</div>
					</div>
				</div>
			</div>
			<!-- end modal -->

		<vue-loading color="#212121" :active.sync="loading" :can-cancel="false" :is-full-page="fullPage"></vue-loading>
		</div>
	</div>
</template>

<script>
	import ApiService from '../../apiservice.js';
	export default {
		mixins: [ApiService],
		props: ['permissions'],
		data() {
			return {
				accountsTitle: "Nova Conta",
				account: {
					type: null,
					coin: 'Real',
					coin_symbol: 'R$',
					name: null,
					bank: null,
					agency: null,
					number: null,
					limit: null,
					contact: null,
					phone: null,
					initial_balance: null,
				},
				banksOptions: [],
				isEditAccounts: false,
				accountsID: "",
				accountsItems: [],
				fullPage: true,
				errors: [],
				loading: false,
				money: {
          decimal: ',',
          thousands: '.',
          prefix: '',
          suffix: '',
          precision: 2,
          masked: false
        },
				typeOptions: [{
					id: 'current_account',
					label: "Conta Corrente"
				},{
					id: 'money',
					label: "Dinheiro"
				},{
					id: 'investments',
					label: "Investimentos"
				},{
					id: 'other',
					label: "Outros"
				}],
				tablePagination: {
					enabled: true,
					nextLabel: "próxima",
					prevLabel: "anterior",
					rowsPerPageLabel: "Registros por página",
					tofLabel: "de",
					pageLabel: "página",
					allLabel: "Todos",
				},
				tableColumns: [
					{
						label: 'Nome',
						field: 'name',
					},{
						label: 'Tipo',
						field: '_type',
					},{
						label: 'Data e Hora',
						field: 'created_at',
						type: 'date',
						dateInputFormat: "yyyy-MM-dd'T'HH:mm:ss.SSSSSSXXX",
						dateOutputFormat: 'dd/MM/yyyy HH:mm:ss'
					},{
						label: 'Ações',
						field: 'actions',
						sortable: false,
						width: '100px'
					}
				],
				userCan: ''
			}
		},
		mounted() {
			this.userCan = JSON.parse(this.permissions);
			if(this.userCan.list){
				this.loadItems();
			}
			this.loadBanks();
		},
		methods: {
			loadItems() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/accounts").then((res) => {
					_this.accountsItems = _this.accountsItems.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			loadBanks() {
				let _this = this;
				_this.loading = true;
				axios.get("/admin/banks").then((res) => {
					_this.banksOptions = [];
					_this.banksOptions = _this.banksOptions.concat(res.data);
					_this.loading = false;
				}).catch((err) => {
					_this.loading = false;
				});
			},
			saveData() {
				let _this = this;
				_this.errors = [];
				_this.loading = true;

				if (_this.isEditAccounts == true){

					let dataInfo = {
						"type" : _this.account.type,
						"coin" : _this.account.coin,
						"coin_symbol" : _this.account.coin_symbol,
						"name" : _this.account.name,
						"bank" : _this.account.bank,
						"agency" : _this.account.agency,
						"number" : _this.account.number,
						"limit" : _this.account.limit,
						"contact" : _this.account.contact,
						"phone" : _this.account.phone,
						"initial_balance" : _this.account.initial_balance,
						"_method": "PATCH"
					};
					axios.post("/admin/accounts/"+_this.accountsID, dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						$('#modalAccounts').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});

				} else {

					let dataInfo = {
						"type" : _this.account.type,
						"coin" : _this.account.coin,
						"coin_symbol" : _this.account.coin_symbol,
						"name" : _this.account.name,
						"bank" : _this.account.bank,
						"agency" : _this.account.agency,
						"number" : _this.account.number,
						"limit" : _this.account.limit,
						"contact" : _this.account.contact,
						"phone" : _this.account.phone,
						"initial_balance" : _this.account.initial_balance,
						"last": ""
					};
					axios.post("/admin/accounts", dataInfo).then((res) => {

						_this.loading = false;
						_this.resetForm();

						_this.accountsItems.push(res.data.data);
						$('#modalAccounts').modal('hide');

						//call function in ApiService to validate success
						_this._api_validateSuccess(res,false);

					}).catch((err) => {

						_this.loading = false;

						//call function in ApiService to validate error
						_this._api_validateError(err);

					});
				}
			},
			deleteData(ID, index) {
				let _this = this;

				//show alert confirmation
				_this.$swal({
					title: "Excluir Conta",
					text: "Tem certeza que deseja excluir essa Conta?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "Excluir",
					cancelButtonText: "Cancelar"
				}).then((alertConfirm) => {

					//validate isConfirmed
					if (alertConfirm.isConfirmed) {

						//remove
						axios.delete("/admin/accounts/"+ID).then((res) => {

							_this.accounts.splice(index, 1);

							//call function in ApiService to validate success
							_this._api_validateSuccess(res,true);

						}).catch(err => {

							_this.loading = false;

							//call function in ApiService to validate error
							_this._api_validateError(err);

						});

					}//end validate isConfirmed

				});
				//end alert confirmation

			},
			editData(item){

				this.account = item;
				this.account.bank = item.bank_id;
				this.isEditAccounts = true;
				this.accountsID = item.id;
				this.accountsTitle = "Editar Conta";

				$("#modalAccounts").modal('show');

			},
			resetForm(){

				this.account = {
					type: null,
					coin: 'Real',
					coin_symbol: 'R$',
					name: null,
					bank: null,
					agency: null,
					number: null,
					limit: null,
					contact: null,
					phone: null,
					initial_balance: null,
				};

				this.errors = [];
				this.isEditAccounts = false;
				this.accountsID = "";
				this.accountsTitle = "Nova Conta";

			}
		}
	}
</script>
